import EditorJsRenderer from '../../common/EditorJsRenderer/EditorJsRenderer'
import { useResponsiveScreen } from '../../common/Utils'

const ImageBannerPreview = ({
  state,
  customImageState,
  includeHeader = true,
  customLayoutState,
  showOverlay = true,
  customOverlayState,
  showContent = true,
  templateData,
}) => {
  // Allow custom state handling for component step previews
  const imageState = customImageState ?? state?.image
  const layoutState = customLayoutState ?? state?.layout
  const overlayState = customOverlayState ?? state?.overlay
  const screenType = useResponsiveScreen()

  const BannerLayoutStyles = () => {
    return {
      width: '100%',
      height:
        layoutState?.type === 'customAspectRatio'
          ? ''
          : layoutState.type === 'fullSize'
          ? 'auto'
          : layoutState.type === 'customHeight'
          ? screenType === 'mobile'
            ? layoutState?.customHeight?.mobileHeight
            : layoutState?.customHeight?.desktopHeight
          : screenType === 'mobile'
          ? '250px'
          : '450px',
      aspectRatio:
        layoutState?.type === 'customAspectRatio'
          ? `${layoutState?.customAspectRatio?.width} / ${layoutState?.customAspectRatio?.height}`
          : '',
    }
  }

  const ImageStyles = () => {
    return layoutState?.imageLayout === 'contain' || layoutState?.type === 'fullSize'
      ? {
          objectFit: 'contain',
          width: '100%',
          height: '100%',
        }
      : {
          objectFit: 'cover',
          objectPosition: 'center',
          width: '100%',
          height: '100%',
          position: 'absolute',
          overflow: 'hidden',
        }
  }

  const OverlayStyles = () => {
    return {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: overlayState?.color,
      opacity: `calc(${overlayState?.opacity} / 100)`,
    }
  }

  return (
    <div className="w-100">
      {imageState?.image?.url ||
      (imageState?.image?.url === 'default' && imageState?.image?.previewUrl !== undefined) ? (
        <>
          {includeHeader && (
            <>
              <h6 className="mb-0">Banner Layout Preview</h6>
              <p className="small">
                Please note that this is a preview for a 1080 x 1920 screen size
              </p>
            </>
          )}
          {/* Banner Layout */}
          <div
            className="d-flex justify-content-center align-items-center overflow-hidden position-relative"
            style={BannerLayoutStyles()}
          >
            {/* Image */}
            <img
              src={
                imageState?.image?.url === 'default'
                  ? imageState?.image?.previewUrl
                  : imageState?.image?.url
              }
              alt="preview file"
              style={ImageStyles()}
            />
            {/* Overlay */}
            {showOverlay && overlayState?.active === 'true' && (
              <div className="position-absolute" style={OverlayStyles()}></div>
            )}
            {/* Content */}
            {showContent && state?.content?.active === 'true' && (
              <div
                className="position-absolute w-100 h-100 d-flex flex-column justify-content-center align-items-center"
                style={{
                  top: 0,
                  left: 0,
                }}
              >
                <EditorJsRenderer data={{ blocks: state?.content?.blocks }} />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {/* Custom Warning for Search page Banner */}
          {templateData?.type === 'search' && state.searchBanner ? (
            <div className="my-2">
              <span className="badge badge-info">Optional Image Banner</span>{' '}
              <span className="small">
                Upload an image if you want to display a banner on your search page.
              </span>
            </div>
          ) : (
            <div className="my-2">
              <span className="badge badge-warning">Warning</span>{' '}
              <span className="small">Please select or upload an image.</span>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ImageBannerPreview
