import { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';
import { ready } from '../entries/utils'
import Select from 'react-select'
import { standardHeaders } from '../entries/utils';

let attributeOptions = [
  { label: "photos_count", value: "photos_count", type: "integer" },
  { label: "stock_image_count", value: "stock_image_count", type: "integer" },
  { label: "photos_count or stock_image_count", value: "photos_count_or_stock_image_count", type: "integer" },
  { label: "photos_count and stock_image_count", value: "photos_count_and_stock_image_count", type: "integer" },
  { label: "views", value: "views", type: "integer" },
  { label: "reserved", value: "reserved", type: "boolean" },
  { label: "EGC Price", value: "egcprice", type: "integer" },
  { label: "DAP Price", value: "dapprice", type: "integer" },
  { label: "Odometer", value: "km", type: "integer" },
  { label: "EGC Price or DAP Price", value: "egcprice_or_dapprice", type: "integer" },
  { label: "EGC Price and DAP Price", value: "egcprice_and_dapprice", type: "integer" },
  { label: "Year", value: "year", type: "integer" },
  { label: 'Location Code', value: 'location_code', type: 'string' },
  { label: 'Rego', value: 'regplate', type: 'string' },
  { label: 'Vin', value: 'vin', type: 'string' },
  { label: 'Total Lead Count', value: 'leads_count', type: 'integer' },
  { label: 'Lead Count last 24 hours', value: 'leads_last_24_hours', type: 'integer' },
  { label: 'Lead Count last 72 hours', value: 'leads_last_72_hours', type: 'integer' },
  { label: '"A" grade cars with days old', value: 'a_grade_score_with_days_old', type: 'integer' },
  { label: '"A" or "B" grade cars with days old', value: 'a_or_b_grade_score_with_days_old', type: 'integer' },
  { label: 'Manufacturer', value: 'manufacturer_id', type: 'string' },
  { label: 'DMS Status', value: 'dms_status', type: 'string' },
  { label: 'Redbook code', value: 'redbook_code', type: 'string' },
  { label: 'Dealercomments', value: 'dealercomments', type: 'string' },
  { label: 'Car Type', value: 'car_type', type: 'string' },
]

let defaultFilters = [
  { intrinsic_id: "new_0", filter_attribute: undefined, operator: undefined, value: undefined }
]

let booleanOptions = [
  { label: "false", value: 'false' },
  { label: "true", value: 'true' }
]

let operatorOptionsForNonInteger = [
  { label: "Equal To", value: "equal_to" },
  { label: "Is Empty", value: "is_empty" },
  { label: "Is Not Empty", value: "is_not_empty" },
  { label: "Included in", value: "included_in" },
  { label: "Not Included in", value: "not_included_in" },
  { label: "Begins With", value: "begins_with" },
]

let Filter = ({ filter, filters, setFilters }) => {

  let operatorOptions = window.operatorOptions.map(oo => {
    return { label: oo, value: oo }
  })

  let [operator, setOperator] = useState(filter.operator)
  let [filter_attribute, setFilterAttribute] = useState(filter.filter_attribute)
  let [value, setValue] = useState(filter.value)
  let [destroy, setDestroy] = useState(false)

  useEffect(() => {
    if (operator && filter_attribute && (value || acceptsEmptyValue(operator))) {
      let updatedFilters = filters.map(f => {
        if (f.id === filter.id || (f.intrinsic_id && f.intrinsic_id === filter.intrinsic_id)) {
          return {
            id: filter.id,
            filter_attribute: filter_attribute,
            operator: operator,
            value: value,
            intrinsic_id: f.intrinsic_id,
            _destroy: destroy
          };
        }
        return f;
      });

      setFilters(updatedFilters)
    }
  }, [operator, filter_attribute, value, destroy]);

  const attribute = attributeOptions.find(option => option.value === filter_attribute);
  let attributeType = attribute ? attribute.type : null;

  return (
    <tr>
      <td>
        <Select
          options={attributeOptions}
          onChange={e => setFilterAttribute(e.value)}
          value={attributeOptions.filter(o => o.value === filter_attribute)[0]}
          placeholder="Attribute..."
        />
      </td>
      <td>
        {filter_attribute && (
          <Select
            options={attributeType === "integer" ? operatorOptions : operatorOptionsForNonInteger}
            onChange={e => setOperator(e.value)}
            value={operatorOptions.filter(o => o.value === operator)[0]}
            placeholder="Operator..."
          />
        )}
      </td>
      <td>
        {filter_attribute && (
          attributeType === "boolean" ? (
            <Select
              value={booleanOptions.filter(o => o.label === value)[0]}
              onChange={e => setValue(e.value)}
              placeholder="Value..."
              options={booleanOptions}
            />
          ) : (
            <input
              defaultValue={value}
              onChange={e => setValue(e.target.value)}
              placeholder="Value..."
              className="form-control"
            />
          )
        )}
        {(['included_in', 'not_included_in'].includes(operator)) && <span className="text-secondary small">Comma seperated values</span>}
      </td>
      <td>
        {filter.id && (
          <div className="form-check">
            <input
              type="checkbox"
              defaultValue={destroy}
              onChange={e => setDestroy(e.target.checked)}
              id={`${filter.id || filter.intrinsic_id}_label`}
              className="form-check-input"
            />
            <label
              htmlFor={`${filter.id || filter.intrinsic_id}_label`}
              className="form-check-label"
            >
              Remove
            </label>
          </div>
        )}
      </td>
    </tr>
  )
}

let acceptsEmptyValue = (operator) => ['is_empty', 'is_not_empty'].includes(operator);

let submitData = (data, setLoading, setFilters, setErrors, url, object_type) => {
  setLoading(true)
  fetch(url, {
    method: 'put',
    body: JSON.stringify({
      [object_type]: {
        stock_filters_attributes: data.filter(sf => (sf.filter_attribute && (sf.value || acceptsEmptyValue(sf.operator)) && sf.operator))
      }
    }),
    headers: standardHeaders,
  }).then(res => res.json())
    .then(res => {
      if (res.errors) {
        setErrors(res.errors)
      } else {
        setFilters(res)
      }
      setLoading(false)
    })
    .catch(() => {
      setLoading(false)
    })
}

let App = ({ url, object_type }) => {
  let [filters, setFilters] = useState(window.existing_stock_filters || defaultFilters)
  let [loading, setLoading] = useState(false)
  let [errors, setErrors] = useState(false)

  return (
    <>
      <div className="box mb-3">
        <table className="table">
          <thead>
            <tr>
              <th>Attribute</th>
              <th>Operator</th>
              <th>Value</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filters.map((filter, index) => (
              <Filter
                filter={filter}
                filters={filters}
                setFilters={setFilters}
                key={index}
              />
            ))}
          </tbody>
        </table>
        <div
          className="btn btn-outline-secondary mb-3 ml-3"
          onClick={e => {
            setFilters([
              ...filters,
              { intrinsic_id: `new_${filters.length}`, filter_attribute: undefined, operator: undefined, value: undefined }
            ])
          }}
        >
          Add New
        </div>
      </div>
      {errors && <p className="text-danger">{errors}</p>}
      <div className="text-right">
        <div
          className="btn btn-outline-success"
          onClick={e => submitData(filters, setLoading, setFilters, setErrors, url, object_type)}
        >
          {loading ? "Loading..." : "Save"}
        </div>
      </div>
    </>
  )
}

ready(() => {
  let stock_filters = document.getElementById('stock-filters')
  if (stock_filters) {
    let object_type = stock_filters.getAttribute('object_type')
    let url = stock_filters.getAttribute('url')
    const root = ReactDOM.createRoot(stock_filters)
    root.render(<App url={url} object_type={object_type} />);
  }
})